<!--企业采购月报表-->
<template>
    <div class="purchaseMonthReport table-bg">
        <reportTable
            code="PurchaseDay,PurchaseSupplementData"
            :query="query"
            @generateComplete="generateComplete"
            :percentage="percentage"
            :toolbar="['exportTable']"
            :custom-date-method="customDateMethod"
            table-title="企业采购月报表"
        >
            <template #queryItems>
                <div class="query-item">
                    <label class="name">日期选择：</label>
                    <el-date-picker
                        v-model="monthDate"
                        type="month"
                        placeholder="请选择"
                        value-format="yyyy-MM"
                        :editable="false"
                        :clearable="false"
                    >
                    </el-date-picker>
                </div>
            </template>
            <template #customTable>
                <div class="table-head">
                    <table class="t-table" cellpadding="0" cellspacing="0">
                        <thead>
                            <tr>
                                <th style="width: 1.2rem" rowspan="2">
                                    序号
                                </th>
                                <th style="width: 1.2rem" rowspan="2">
                                    项 目
                                </th>
                                <th style="width: 1.2rem" rowspan="2">
                                    单位
                                </th>
                                <th colspan="3">
                                    本月采购内容
                                </th>
                                <th colspan="2">
                                    上月采购内容
                                </th>
                                <th style="width: 1rem" rowspan="2">
                                    环比降幅（%）
                                </th>
                                <th colspan="3">
                                    年累计
                                </th>
                                <th colspan="2">
                                    期末库存
                                </th>
                                <th style="width: 1.2rem" rowspan="2">
                                    上月期末应付账款<br>
                                    （含税，万元）
                                </th>
                                <th style="width: 1.2rem" rowspan="2">
                                    本月支付账款<br>
                                    （含税，万元）
                                </th>
                                <th style="width: 1.2rem" rowspan="2">
                                    应付账款<br>
                                    （含税，万元）
                                </th>
                            </tr>
                            <tr>
                                <th style="width: 1.2rem">
                                    采购量
                                </th>
                                <th style="width: 1.2rem">
                                    采购单价<br>
                                    （不含税，元）
                                </th>
                                <th style="width: 1.2rem">
                                    采购金额<br>
                                    （不含税，万元）
                                </th>
                                <th style="width: 1.2rem">
                                    采购量
                                </th>
                                <th style="width: 1.2rem">
                                    采购单价<br>
                                    （不含税，元）
                                </th>
                                <th style="width: 1.2rem">
                                    采购量
                                </th>
                                <th style="width: 1.2rem">
                                    采购单价<br>
                                    （不含税，元）
                                </th>
                                <th style="width: 1.3rem">
                                    采购金额<br>
                                    （不含税，万元）
                                </th>
                                <th style="width: 1.2rem">
                                    库存量
                                </th>
                                <th style="width: 1.2rem">
                                    库存金额<br>
                                    （含税，万元）
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="table-body">
                    <table
                        v-if="tableList.length>0"
                        class="t-table"
                        cellpadding="0"
                        cellspacing="0"
                    >
                        <tbody>
                            <template v-for="(item,index) in tableList">
                                <tr :key="index">
                                    <td style="width: 1.2rem">
                                        {{ index+1 }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item.type_name }}
                                    </td>
                                    <td v-if="index===0" style="width: 1.2rem" :rowspan="tableList.length">
                                        {{ item.unit }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item.month_net_weight }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item.month_unit_price }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item.month_subtotal }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item.last_month_net_weight }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item.last_month_unit_price }}
                                    </td>
                                    <td style="width: 1rem">
                                        {{ item.chain_decline }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item.year_net_weight }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item.year_unit_price }}
                                    </td>
                                    <td style="width: 1.3rem">
                                        {{ item.year_subtotal }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item.stock }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item.stock_money }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item.last_month_accounts_payable }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item.payment }}
                                    </td>
                                    <td style="width: 1.2rem">
                                        {{ item.accounts_payable }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <div class="el-table__empty-text" v-if="tableList.length == 0"></div>
                </div>
            </template>
        </reportTable>
    </div>
</template>

<script>
import reportTable from '@/components/reportTable/reportTable.vue';
import utils from '@/utils/util';
export default {
    components: { reportTable },
    data() {
        return {
            loading: false,
            query: {},
            monthDate: '',
            totalData: {},
            tableList: [],
            // 时间分批大小 ：天
            limit: 10,
            // 进度条
            percentage: 100,
            // 合并单元格配置
            mergeCells: {},
            // 查询分组
            queryArr: null,
        };
    },
    created() {
        this.monthDate = utils.timeFormat(new Date(), 'yyyy-MM');
    },
    methods: {

        // 自定义查询时间
        customDateMethod(params) {
            params.startTime = utils.timeFormat(new Date(this.monthDate), 'yyyy-MM-01');
            const maxDates = this.getMonthDays(this.monthDate);
            params.endTime = utils.timeFormat(new Date(this.monthDate), 'yyyy-MM-' + maxDates);
        },
        getMonthDays(dateStr) {
            const d = new Date(dateStr);
            return new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
        },
        // 报表生成完成
        async generateComplete() {
            try {
                this.loading = true;
                this.tableList = [];
                this.mergeCells = {};
                this.percentage = 0;
                const listUrl = `/interfaceApi/report/purchase_month_report/data_list?date=${this.monthDate}`;
                this.tableList = await this.$axios.get(listUrl);
                this.loading = false;
                this.percentage = 100;
            } catch (e) {
                this.$message.error(e.ErrorCode ? e.ErrorCode.Message : e.message);
            } finally {
                this.percentage = 100;
                this.loading = false;
            }
        },
    },
    destroyed() {
        this.queryArr = null;
    },
};
</script>
<style lang="stylus" scoped>
</style>
